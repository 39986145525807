'use strict';

Gri.module({
  name: 'search-input',
  ieVersion: null,
  $el: $('.search-input'),
  container: '.search-input',
  fn: function () {

    $('.search-input input[type="text"]').keyup(function(e){
      if(e.keyCode == 13){
        $('.search-input input[type="submit"]').trigger("click");
      }
    });
    
  }
});
